<template>
  <v-app id="museotek">
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackbar.open" :color="snackbar.color" timeout="5000">
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>
        <v-btn text small class="ml-auto" @click="onCloseSnackbar"
          >Κλείσιμο</v-btn
        >
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      isMobile: navigator.userAgentData.mobile,
      isDevelopment: process.env.NODE_ENV == "development",
    };
  },
  computed: {
    ...mapState({
      snackbar: (state) => state.base.snackbar,
      room: (state) => state.twilio.room,
      localTracks: (state) => state.twilio.localTracks,
      localRef: (state) => state.twilio.localRef,
    }),
  },
  created() {
    window.addEventListener("beforeunload", () => {
      if (this.room?.state == "connected") this.room?.disconnect();
    });

    if (this.isMobile && !this.isDevelopment) {
      document.addEventListener("visibilitychange", async () => {
        if (this.room?.state == "connected") {
          let videoTrack = this.localTracks.find(
            (track) => track.kind === "video"
          );

          if (document.visibilityState === "hidden") {
            videoTrack.stop();
            videoTrack.disable();
          } else {
            videoTrack.restart({ facingMode: "environment" });
            videoTrack.enable();
          }
        }
      });
    }
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    onCloseSnackbar() {
      this.toggleSnackbar({
        open: false,
      });
    },
  },
};
</script>

<style lang="scss">
//====== VUETIFY OVERRIDES ======

#museotek.v-application {
  *:not(.v-icon),
  *:not(.v-icon):before,
  *:not(.v-icon):after {
    font-family: "Open Sans", sans-serif !important;
  }

  .text-h6,
  .font-weight-medium {
    font-weight: 600 !important;
  }
}

.v-main__wrap {
  display: flex;
}

.v-btn.v-size--small {
  .v-progress-circular {
    height: 19px !important;
    width: 19px !important;
  }
}

.v-divider.theme--dark {
  z-index: 1;
  border-color: white !important;
}

.v-btn:not(.v-btn--icon) {
  letter-spacing: normal !important;
  text-transform: none !important;
  font-size: 14px !important;
  height: 33px !important;
  border-radius: 2px !important;
}

//====== GLOBAL STYLES ======
.component-wrapper {
  flex-grow: 1;
  position: relative;

  &.video > div {
    flex: 1;
    display: flex;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.local,
.remote {
  position: relative;

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.track-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 3px 0 3px;
}

.live-video-wrapper {
  height: 1px;
  display: flex;

  video {
    flex-grow: 1;
    object-fit: contain;
  }
}
</style>
