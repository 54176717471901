<template>
  <div class="menu-wrapper d-flex black white--text text-center">
    <div
      class="menu-item d-flex flex-column align-center primary mr-2 pa-5 pt-10"
      @click="changeMode('camera')"
    >
      <img src="@/assets/menuCamera.svg" class="mb-3" />
      <div class="text-h4 font-weight-bold mb-3">Camera</div>
      <div class="body-1">
        Δείτε την κάμερα του σχολείου ενώ κάνετε την ξενάγησή σας
      </div>
    </div>

    <div
      class="menu-item d-flex flex-column align-center accent mr-2 pa-5 pt-10"
      @click="changeMode('presentation')"
    >
      <img src="@/assets/menuPresentation.svg" class="mb-3" />
      <div class="text-h4 font-weight-bold mb-3">Πρόγραμμα</div>
      <div class="body-1">
        Δείτε το πρόγραμμα και ξεκινήστε την παρουσίασή σας
      </div>
    </div>

    <div
      class="menu-item d-flex flex-column align-center secondary pa-5 pt-10"
      @click="changeMode('library')"
    >
      <img src="@/assets/menuLibrary.svg" class="mb-3" />
      <div class="text-h4 font-weight-bold mb-3">Βιβλιοθήκη</div>
      <div class="body-1">
        Περιηγηθείτε στα αρχεία σας και επιλέξτε αυτό που επιθυμείτε να
        παρουσιάσετε
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["toggleMenu"]),
    changeMode(mode) {
      if (mode == "camera") {
        this.$router.push({ path: "/room/camera" });
      } else {
        this.$router.push({ path: `/room/content/${mode}` });
      }

      this.toggleMenu(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  height: 100vh;
}

.menu-item {
  flex: 1;
  cursor: pointer;

  img {
    height: 90px;
  }
}
</style>
