<template>
  <div class="component-wrapper d-flex py-2">
    <v-progress-circular
      class="ma-auto"
      size="50"
      indeterminate
      color="primary"
      v-if="loader"
    ></v-progress-circular>

    <div
      class="image-wrapper flex-grow-1"
      v-if="!loadContentLoader"
      v-show="!loader"
    >
      <img :src="`${baseUrl}/${openedContent.path}`" @load="loadImage" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      loadContentLoader: false,
    };
  },
  computed: {
    ...mapState({
      roomContent: (state) => state.guide.roomContent,
      remoteConnected: (state) => state.twilio.remoteConnected,
      openedContent: (state) => state.guide.openedContent,
    }),
    loader() {
      if (this.remoteConnected) {
        return (
          !this.roomContent.schoolImageLoaded ||
          !this.roomContent.guideImageLoaded
        );
      } else {
        return !this.roomContent.guideImageLoaded;
      }
    },
  },
  async created() {
    this.loadContentLoader = true;
    await this.loadContent();
    this.loadContentLoader = false;
  },
  methods: {
    ...mapMutations(["setOpenedContentFromPresentation"]),
    ...mapActions(["loadContent", "loadImage", "unloadContent"]),
  },
  async beforeRouteUpdate(to, from, next) {
    this.loadContentLoader = true;
    this.setOpenedContentFromPresentation();
    await this.loadContent();
    this.loadContentLoader = false;

    next();
  },
  async beforeRouteLeave(to, from, next) {
    if (!to.path.includes("/video/")) this.unloadContent();

    next();
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>