<template>
  <div>
    <template v-if="isPresentation">
      <v-btn icon color="white" class="primary mr-2" @click="onPrevSection">
        <v-icon>mdi-chevron-double-left</v-icon>
      </v-btn>

      <v-btn icon color="white" class="primary mr-4" @click="onNextSection">
        <v-icon>mdi-chevron-double-right</v-icon>
      </v-btn>

      <v-btn icon color="white" class="primary mr-2" @click="onPrevFile">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn icon color="white" class="primary mr-4" @click="onNextFile">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </template>

    <v-btn
      icon
      color="white"
      :class="{
        'grey lighten-1': !allowPlay,
        primary: allowPlay,
      }"
      :disabled="!allowPlay"
      @click="onToggleVideo"
    >
      <v-icon>{{ isVideoPlaying ? "mdi-pause" : "mdi-play" }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      openedContent: (state) => state.guide.openedContent,
      presentationActiveFile: (state) => state.guide.presentationActiveFile,
      roomContent: (state) => state.guide.roomContent,
      remoteConnected: (state) => state.twilio.remoteConnected,
      isVideoPlaying: (state) => state.guide.isVideoPlaying,
      loadContentLoader: (state) => state.guide.loadContentLoader,
    }),
    allowPlay() {
      const isVideo = this.openedContent.type == "video";

      if (!isVideo) return false;

      if (this.remoteConnected) {
        return (
          this.roomContent.schoolVideoLoaded &&
          this.roomContent.guideVideoLoaded
        );
      } else {
        return this.roomContent.guideVideoLoaded;
      }
    },
    isPresentation() {
      return this.$route.path.includes("/presentation/");
    },
  },
  methods: {
    ...mapActions([
      "nextFile",
      "prevFile",
      "nextSection",
      "prevSection",
      "playVideo",
      "pauseVideo",
    ]),
    async onNextFile() {
      if(this.loadContentLoader) return;

      await this.nextFile();

      this.$router.push({
        path: `/room/content/presentation/${this.presentationActiveFile.type}/${this.presentationActiveFile.id}`,
      });
    },
    async onPrevFile() {
      if(this.loadContentLoader) return;

      await this.prevFile();

      this.$router.push({
        path: `/room/content/presentation/${this.presentationActiveFile.type}/${this.presentationActiveFile.id}`,
      });
    },
    async onNextSection() {
      if(this.loadContentLoader) return;

      await this.nextSection();

      this.$router.push({
        path: `/room/content/presentation/${this.presentationActiveFile.type}/${this.presentationActiveFile.id}`,
      });
    },
    async onPrevSection() {
      if(this.loadContentLoader) return;

      await this.prevSection();

      this.$router.push({
        path: `/room/content/presentation/${this.presentationActiveFile.type}/${this.presentationActiveFile.id}`,
      });
    },
    async onToggleVideo() {
      if (this.isVideoPlaying) {
        await this.pauseVideo();
      } else {
        await this.playVideo();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>