<template>
  <div class="component-wrapper d-flex flex-column align-center pa-5">
    <img class="logo mb-4" src="@/assets/logo.svg" />

    <template>
      <v-btn-toggle v-model="type" color="primary" class="mb-6" mandatory>
        <v-btn class="mr-2" width="167px"> Πρόγραμμα </v-btn>

        <v-btn width="167px"> Απευθείας Μετάδοση </v-btn>
      </v-btn-toggle>

      <Program v-if="type == 0" />

      <Live v-if="type == 1" />
    </template>

    <v-btn
      @click="onCustomInstall"
      v-if="showInstallBtn"
      depressed
      class="mt-8 primary"
      >Εγκατάσταση εφαρμογής</v-btn
    >
  </div>
</template>

<script>
import Program from "@/views/home/Program";
import Live from "@/views/home/Live";

export default {
  components: {
    Program,
    Live,
  },
  data() {
    return {
      type: 0,
      installEvent: null,
      showInstallBtn: false,
      isDevelopment: process.env.NODE_ENV == "development",
      isMobile: navigator.userAgentData.mobile,
    };
  },
  async created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.showInstallBtn = true;
    });
  },
  computed: {
    isPWA() {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;

      if (navigator.standalone || isStandalone) {
        return true;
      }

      return false;
    },
  },
  methods: {
    async onCustomInstall() {
      this.installEvent.prompt();

      const choiceResult = await this.installEvent.userChoice;

      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    },
    onOpenApp() {
      window.open("https://testing-5b63a.web.app/");
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 30px;
}
</style>
