<template>
  <div class="component-wrapper d-flex flex-column">
    <div class="library-grid-wrapper pt-1">
      <v-row no-gutters>
        <v-col
          v-for="(item, index) in libraryItems"
          :key="index"
          cols="3"
          class="square pa-2"
        >
          <div
            class="col-content d-flex flex-column align-center justify-center"
            @click="
              $router.push({
                path: `/room/content/library/${item.type}/${item.id}`,
              })
            "
          >
            <img
              :src="
                item.type == 'image'
                  ? `${baseUrl}/${item.thumb}`
                  : `${item.thumb}`
              "
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  computed: {
    ...mapGetters(["libraryItems"]),
  },
};
</script>

<style lang="scss" scoped>
.library-grid-wrapper {
  flex: 1 0 auto;
  overflow-y: auto;
  height: 0px;
}

.square {
  position: relative;
  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.col-content {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  background-color: #f5f5f5;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>