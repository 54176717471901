<template>
  <div class="component-wrapper d-flex">
    <router-view></router-view>

    <v-dialog
      v-model="menu"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <Menu />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Menu from "@/views/program/Menu";

export default {
  components: {
    Menu,
  },
  computed: {
    ...mapState({
      triggersetListeners: (state) => state.twilio.triggersetListeners,
      menu: (state) => state.base.menu,
      questions: (state) => state.guide.questions,
      room: (state) => state.twilio.room,
    }),
    questionsLength() {
      return this.questions.length;
    },
  },
  methods: {
    ...mapActions(["setListeners"]),
    ...mapMutations(["toggleSnackbar", "setQuestionBadge", "resetTwilioState"]),
  },
  watch: {
    triggersetListeners(newValue) {
      if (newValue) {
        this.setListeners();
      }
    },
    questionsLength() {
      this.toggleSnackbar({
        open: true,
        color: "primary",
        text: " Έχετε μια ερώτηση",
      });

      if (!this.$route.path.includes("/questions")) this.setQuestionBadge(true);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.room.state == "disconnected") {
      next();
    } else {
      next(false);
    }
  },
  destroyed() {
    this.resetTwilioState();
  },
};
</script>

<style lang="scss" scoped>
</style>