import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Home from '../views/home/Home.vue'
import Room from '@/views/program/Room'
import Camera from '@/views/program/Camera'
import ContentWrapper from '@/views/program/ContentWrapper'
import Library from '@/views/program/Library'
import LibraryContent from '@/views/program/LibraryContent'
import Presentation from '@/views/program/Presentation'
import Image from '@/views/program/Image'
import Video from '@/views/program/Video'
import Questions from '@/views/program/Questions'

import LiveRoom from '@/views/live/LiveRoom'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    })
};

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/live',
    name: "liveRoom",
    component: LiveRoom
  },
  {
    path: '/room',
    component: Room,
    beforeEnter(to, from, next) {
      if (store.state.twilio.room) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: "/",
        redirect: 'camera'
      },
      {
        path: 'camera',
        component: Camera
      },
      {
        path: 'content',
        component: ContentWrapper,
        children: [
          {
            path: "/",
            redirect: 'library'
          },
          {
            path: 'library',
            component: Library,
            children: [
              {
                path: "/",
                component: LibraryContent
              },
              {
                path: "image/:id",
                component: Image,
                beforeEnter(to, from, next) {
                  store.commit("setOpenedContentFromLibrary", to.params.id);

                  if (store.state.guide.openedContent) {
                    next();
                  } else {
                    next('/room/content/library');
                  }
                },
              },
              {
                path: "video/:id",
                component: Video,
                beforeEnter(to, from, next) {
                  store.commit("setOpenedContentFromLibrary", to.params.id);

                  if (store.state.guide.openedContent) {
                    next();
                  } else {
                    next('/room/content/library');
                  }
                },
              }
            ]
          },
          {
            path: 'presentation',
            component: Presentation,
            children: [
              {
                path: "/",
                redirect: to => {
                  return `/room/content/presentation/${store.state.guide.presentationActiveFile?.type}/${store.state.guide.presentationActiveFile?.id}`
                }
              },
              {
                path: "image/:id",
                component: Image,
                beforeEnter(to, from, next) {
                  store.commit("setOpenedContentFromPresentation");

                  if (store.state.guide.openedContent) {
                    next();
                  } else {
                    next('/room/content/camera');
                  }
                },
              },
              {
                path: "video/:id",
                component: Video,
                beforeEnter(to, from, next) {
                  store.commit("setOpenedContentFromPresentation");

                  if (store.state.guide.openedContent) {
                    next();
                  } else {
                    next('/room/content/camera');
                  }
                },
              }
            ]
          },
          {
            path: 'questions',
            component: Questions
          }
        ]
      }
    ]
  },
  {
    path: "*",
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
