<template>
  <div class="d-flex pa-1 camera-buttons">
    <v-btn icon color="white" class="primary mr-2" @click="toggleMenu(true)">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <div class="questions-button mr-2">
      <div class="badge orange" v-if="questionBadge"></div>

      <v-btn icon color="white" class="primary" @click="onOpenQuestions">
        <v-icon>mdi-chat</v-icon>
      </v-btn>
    </div>

    <v-btn icon color="white" class="primary mr-2" @click="switchCamera">
      <v-icon>mdi-sync</v-icon>
    </v-btn>

    <v-btn
      icon
      color="white"
      class="primary mr-2"
      @click="
        changeTrackState({
          kind: 'video',
          action: tracksMuted.video ? 'unmute' : 'mute',
        })
      "
    >
      <v-icon>
        {{ tracksMuted.video ? "mdi-video-off" : "mdi-video" }}
      </v-icon>
    </v-btn>

    <div class="mic-btn-wrapper">
      <div class="tooltip caption grey white--text" v-if="tracksMuted.audio">
        Σε σίγαση
      </div>

      <v-btn
        icon
        color="white"
        class="primary"
        @click="
          changeTrackState({
            kind: 'audio',
            action: tracksMuted.audio ? 'unmute' : 'mute',
          })
        "
      >
        <v-icon>
          {{ tracksMuted.audio ? "mdi-microphone-off" : "mdi-microphone" }}
        </v-icon>
      </v-btn>
    </div>

    <template v-if="isPresentation || isVideo">
      <v-divider vertical class="mx-auto"></v-divider>

      <PresentationButtons />
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PresentationButtons from "@/views/program/PresentationButtons";

export default {
  components: {
    PresentationButtons,
  },
  computed: {
    ...mapState({
      tracksMuted: (state) => state.twilio.tracksMuted,
      questionBadge: (state) => state.base.questionBadge,
    }),
    isPresentation() {
      return this.$route.path.includes("/presentation/");
    },
    isVideo() {
      return this.$route.path.includes("/video/");
    },
  },
  methods: {
    ...mapMutations(["toggleMenu", "setQuestionBadge"]),
    ...mapActions(["changeTrackState", "switchCamera"]),
    onOpenQuestions() {
      this.$router.push({ path: "/room/content/questions" });

      this.setQuestionBadge(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.camera-buttons {
  border-top: 1px solid #e0e0e0;
}

.mic-btn-wrapper {
  position: relative;

  .tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1px 8px;
    border-radius: 3px;
    white-space: nowrap;
  }
}

.questions-button {
  position: relative;

  .badge {
    height: 12px;
    width: 12px;
    border-radius: 50%;

    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 1;
  }
}
</style>
