<template>
  <div class="library-menu-wrapper primary px-3 pt-1 d-flex flex-column">
    <div class="text-h6 white--text text-right mb-1">Βιβλιοθήκη</div>

    <div class="d-flex align-center justify-end white--text body-2 mb-4">
      <div
        :class="{
          'primary--text text--darken-4 font-weight-medium':
            libraryGroupBy == 'type',
        }"
        @click="setLibraryGroupBy('type')"
      >
        Τύπος
      </div>

      <div class="mx-2">|</div>

      <div
        :class="{
          'primary--text text--darken-4 font-weight-medium':
            libraryGroupBy == 'sections',
        }"
        @click="setLibraryGroupBy('sections')"
      >
        Ενότητα
      </div>
    </div>

    <div class="library-menu-inner flex-grow-1">
      <div
        class="d-flex flex-column align-end body-1 white--text"
        v-if="libraryGroupBy == 'type'"
      >
        <div
          class="body-1 mb-1"
          v-for="(category, index) in categories"
          :key="index"
          :class="{
            'primary--text text--darken-4 font-weight-medium':
              category.value == libraryActiveType,
          }"
          @click="setLibraryActiveType(category.value)"
        >
          {{ category.viewValue }}
        </div>
      </div>

      <div class="white--text" v-else>
        <div
          class="d-flex flex-column align-end"
          v-for="(section, index) in sections"
          :key="index"
        >
          <div
            class="body-1 mb-1"
            :class="{
              'primary--text text--darken-4 font-weight-medium':
                section.order == libraryActiveSection.order,
            }"
            @click="setLibraryActiveSection(section)"
          >
            {{ section.order }}. {{ section.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      categories: [
        {
          value: "all",
          viewValue: "Όλα",
        },
        {
          value: "image",
          viewValue: "Εικόνες",
        },
        {
          value: "video",
          viewValue: "Βίντεο",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      libraryGroupBy: (state) => state.guide.libraryGroupBy,
      libraryActiveType: (state) => state.guide.libraryActiveType,
      sections: (state) => state.guide.sections,
      libraryActiveSection: (state) => state.guide.libraryActiveSection,
    }),
  },
  methods: {
    ...mapMutations([
      "setLibraryGroupBy",
      "setLibraryActiveType",
      "setLibraryActiveSection",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.library-menu-wrapper {
  width: 150px;
  flex-shrink: 0;
}

.library-menu-inner {
  flex: 1 0 auto;
  overflow-y: auto;
  height: 0px;
}
</style>