<template>
  <div class="d-flex flex-grow-1">
    <LibraryMenu />

    <LibraryGrid />
  </div>
</template>

<script>
import LibraryMenu from "@/views/program/LibraryMenu";
import LibraryGrid from "@/views/program/LibraryGrid";

export default {
  components: {
    LibraryMenu,
    LibraryGrid,
  },
};
</script>

<style lang="scss" scoped>
</style>