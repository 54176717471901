<template>
  <div>
    <form
      @submit.prevent="submit"
      class="d-flex flex-column form"
      novalidate
      autocomplete="off"
    >
      <v-text-field
        label="Κωδικός Μετάδοσης"
        outlined
        class="flex-grow-0 mb-2"
        dense
        type="email"
        v-model="roomName"
        color="primary"
        hide-details
      ></v-text-field>

      <v-text-field
        label="Όνομα"
        outlined
        class="flex-grow-0 mb-2"
        dense
        type="email"
        v-model="identity"
        color="primary"
      ></v-text-field>

      <v-btn
        color="primary"
        class="align-self-center"
        depressed
        type="submit"
        :loading="loader"
        :disabled="!roomName || !identity"
        >Έναρξη μετάδοσης</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      roomName: "",
      identity: "",
      loader: false,
    };
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    ...mapActions(["connectToStream"]),
    async submit() {
      this.loader = true;

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/live/connectToRoom`,
          {
            roomName: this.roomName,
            identity: this.identity,
          }
        );

        this.$router.push({
          name: "liveRoom",
          params: { accessToken: res.data.accessToken },
        });
      } catch (e) {
        if (e.response.status == 400) {
          this.toggleSnackbar({
            open: true,
            color: "error",
            text: "Δεν υπάρχει διαθέσιμο πρόγραμμα με αυτόν τον κωδικό. Παρακαλούμε δοκιμάστε άλλον κωδικό.",
          });
        } else {
          this.toggleSnackbar({
            open: true,
            color: "error",
            text: "Κάτι πήγε στραβά. Δοκιμάστε ξανά αργότερα",
          });
        }

        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 250px;
}
</style>