<template>
  <div class="component-wrapper d-flex">
    <div class="d-flex flex-column flex-grow-1">
      <div class="live-video-wrapper flex-grow-1" ref="local"></div>
    </div>

    <div class="buttons pa-1 d-flex flex-column">
      <v-btn icon color="white" class="error mb-2" @click="$router.push('/')">
        <v-icon>mdi-phone-hangup</v-icon>
      </v-btn>

      <v-btn icon color="white" class="primary mb-2" @click="switchCamera">
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        class="primary mb-2"
        @click="
          changeTrackState('video', mutedTracks.video ? 'unmute' : 'mute')
        "
      >
        <v-icon>
          {{ mutedTracks.video ? "mdi-video-off" : "mdi-video" }}
        </v-icon>
      </v-btn>

      <div class="mic-btn-wrapper">
        <div class="tooltip caption grey white--text" v-if="mutedTracks.audio">
          Σε σίγαση
        </div>

        <v-btn
          icon
          color="white"
          class="primary"
          @click="
            changeTrackState('audio', mutedTracks.audio ? 'unmute' : 'mute')
          "
        >
          <v-icon>
            {{ mutedTracks.audio ? "mdi-microphone-off" : "mdi-microphone" }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="discardDialog.open" max-width="500">
      <div class="white pa-3">
        <div class="body-1 text-center mb-4">
          Θέλετε να αποχωρήσετε από την παρουσίαση;
        </div>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" class="mr-3" @click="discard"
            >Αποχώρηση</v-btn
          >

          <v-btn
            color="primary"
            depressed
            @click="
              discardDialog = { open: false, allowLeave: false, to: null }
            "
            >Παραμονή</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import twilio from "twilio-video";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      facingMode: "environment",
      localTracks: null,
      zoomSettings: null,
      zoomValue: null,
      room: null,
      mutedTracks: {
        video: false,
        audio: false,
      },
      discardDialog: {
        open: false,
        allowLeave: false,
        to: null,
      },
    };
  },
  computed: {
    videoTrack() {
      return this.localTracks?.find((track) => track.kind == "video");
    },
  },
  async mounted() {
    addEventListener("beforeunload", this.onBeforeUnload, { capture: true });

    try {
      this.localTracks = await twilio.createLocalTracks({
        audio: true,
        video: {
          facingMode: this.facingMode,
          height: 1080,
          width: 1920,
          frameRate: 60,
        },
      }); //get local tracks

      this.checkZoomSupport();

      this.room = await twilio.connect(this.$route.params.accessToken, {
        tracks: this.localTracks,
      }); //connect to room

      this.room.localParticipant.videoTracks.forEach((publication) => {
        this.$refs.local.appendChild(publication.track.attach());
      }); //attach local tracks

      this.room.participants.forEach((participant) => {
        //handle already connected participants
        console.log(`Participant "${participant.identity}" already connected`);
      });

      this.room.on("participantConnected", (participant) => {
        //handle participantConnected event
        console.log(`Participant "${participant.identity}" connected`);
      });

      this.room.on("participantDisconnected", (participant) => {
        //handle participantDisconnected event
        console.log(`Participant disconnected: ${participant.identity}`);
      });

      this.room.on("disconnected", (room) => {
        if (this.discardDialog.allowLeave) return;

        this.discard();
      });
    } catch (e) {
      console.log(e);

      this.toggleSnackbar({
        open: true,
        color: "error",
        text: "Κάτι πήγε στραβά. Δοκιμάστε ξανά αργότερα",
      });

      this.discard();
    }
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    changeTrackState(kind, action) {
      this.room.localParticipant.tracks.forEach((publication) => {
        if (publication.kind == kind) {
          if (action == "mute") {
            publication.track.disable();
          } else {
            publication.track.enable();
          }
        }
      });

      this.mutedTracks[kind] = action == "mute" ? true : false;
    },
    async zoom(action) {
      let newZoomValue;

      if (action == "zoomin") {
        if (this.zoomValue + this.zoomSettings.step > this.zoomSettings.max) {
          return;
        }

        newZoomValue = this.zoomValue + this.zoomSettings.step;
      } else {
        if (this.zoomValue - 0.5 < this.zoomSettings.min) {
          return;
        }

        newZoomValue = this.zoomValue - this.zoomSettings.step;
      }

      await this.videoTrack.mediaStreamTrack.applyConstraints({
        advanced: [{ zoom: newZoomValue }],
      });

      const settings = this.videoTrack.mediaStreamTrack.getSettings();

      this.zoomValue = settings.zoom.toFixed(2);
    },
    switchCamera() {
      this.videoTrack.stop();

      const newFacingMode = this.facingMode == "user" ? "environment" : "user";

      this.videoTrack.restart({ facingMode: newFacingMode });

      this.facingMode = newFacingMode;

      this.checkZoomSupport();
    },
    checkZoomSupport() {
      const capabilities = this.videoTrack?.mediaStreamTrack?.getCapabilities();
      const settings = this.videoTrack?.mediaStreamTrack?.getSettings();

      this.zoomSettings = capabilities.zoom
        ? {
            min: capabilities.zoom.min,
            max: capabilities.zoom.max,
            step: capabilities.zoom.step,
          }
        : null;

      this.zoomValue = settings.zoom ? settings.zoom : null;
    },
    discard() {
      this.discardDialog = {
        ...this.discardDialog,
        open: false,
        allowLeave: true,
      };

      if (this.discardDialog.to) {
        this.$router.push({ path: this.discardDialog.to });
      } else {
        this.$router.go(-1);
      }
    },
    onBeforeUnload() {
      this.room?.disconnect();
    },
  },
  beforeRouteLeave(to, from, next) {
    //DISCARD DIALOG LOGIC
    if (!this.discardDialog.allowLeave) {
      this.discardDialog = {
        ...this.discardDialog,
        open: true,
        to: to?.path,
      };

      return next(false);
    }

    this.localTracks?.forEach((track) => {
      track.stop();
    });

    if (this.room?.state == "connected") this.room.disconnect();

    removeEventListener("beforeunload", this.onBeforeUnload, { capture: true });

    next();
  },
};
</script>

<style lang="scss" scoped>
.mic-btn-wrapper {
  position: relative;

  .tooltip {
    position: absolute;
    top: 50%;
    left: -78px;
    transform: translateY(-50%);
    padding: 1px 8px;
    border-radius: 3px;
    white-space: nowrap;
  }
}
</style>