<template>
  <div class="component-wrapper d-flex flex-column">
    <Header />

    <div class="d-flex flex-grow-1">
      <div class="left d-flex">
        <router-view></router-view>
      </div>

      <div class="right d-flex flex-column">
        <div class="half local" ref="local">
          <div
            class="track-msg white--text body-1 text-center"
            v-if="tracksMuted.video"
          >
            Η κάμερά σας είναι απενεργοποιημένη
          </div>
        </div>

        <v-divider dark></v-divider>

        <div class="half remote" ref="remote">
          <div class="track-msg body-2 text-center" v-if="!remoteConnected">
            Το σχολείο δεν έχει συνδεθεί
          </div>

          <div
            class="track-msg body-1 text-center white--text"
            v-if="!remoteVideoState && remoteConnected"
          >
            Το σχολείο έχει κλείσει προσωρινά την κάμερα του
          </div>

          <div
            class="track-msg d-flex flex-column text-center align-center"
            v-if="remoteConnected && schoolNoCamera"
          >
            <v-icon color="warning" class="mb-2">mdi-video-off</v-icon>

            <div class="body-2 warning--text">
              Το σχολείο έχει συνδεθεί χωρίς κάμερα.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/program/Header";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Header,
  },
  computed: {
    ...mapState({
      remoteConnected: (state) => state.twilio.remoteConnected,
      remoteVideoState: (state) => state.twilio.remoteVideoState,
      tracksMuted: (state) => state.twilio.tracksMuted,
      schoolNoCamera: (state) => state.twilio.schoolNoCamera,
    }),
  },
  mounted() {
    this.changeRefs({
      local: this.$refs.local,
      remote: this.$refs.remote,
    });
  },
  methods: {
    ...mapActions(["changeRefs", "detachTracks"]),
  },
  destroyed() {
    this.detachTracks();
  },
};
</script>

<style lang="scss" scoped>
.right {
  width: 25%;
  border-left: 1px solid #e0e0e0;
  flex-shrink: 0;

  .half {
    height: 1px;
    flex-grow: 1;
  }
}

.left {
  flex: 1;
}
</style>