<template>
  <div>
    <form
      @submit.prevent="submit"
      class="d-flex flex-column form"
      novalidate
      autocomplete="off"
    >
      <v-text-field
        label="Κωδικός προγράμματος"
        outlined
        class="flex-grow-0 mb-2"
        dense
        type="email"
        v-model="roomName"
        color="primary"
      ></v-text-field>

      <v-btn
        color="primary"
        class="align-self-center"
        depressed
        type="submit"
        :loading="loader"
        :disabled="!roomName"
        >Έναρξη προγράμματος</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      roomName: "",
      loader: false,
    };
  },
  methods: {
    ...mapActions(["connectToRoom"]),
    async submit() {
      try {
        this.loader = true;
        await this.connectToRoom(this.roomName);
        this.loader = false;
        this.$router.push({ path: "/room" });
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 250px;
}
</style>