import axios from "axios";
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { firestore } from '@/firestore';

export default {
    state: {
        roomContent: null,
        questions: [],
        program: null,
        sections: [],
        libraryGroupBy: 'type',
        libraryActiveType: 'all',
        libraryActiveSection: null,
        presentationActiveSection: null,
        presentationActiveFile: null,
        openedContent: null,
        videoPlayer: null,
        isVideoPlaying: false,
        loadContentLoader: false
    },
    mutations: {
        ...vuexfireMutations,
        getContent(state, program) {
            state.program = program;
            state.sections = [...program.sections];
            delete program.sections;
        },
        setLibraryGroupBy(state, libraryGroupBy) {
            state.libraryGroupBy = libraryGroupBy
        },
        setLibraryActiveType(state, libraryActiveType) {
            state.libraryActiveType = libraryActiveType
        },
        setLibraryActiveSection(state, section) {
            state.libraryActiveSection = section;
        },
        setPresentationActiveSection(state, section) {
            state.presentationActiveSection = section;
        },
        setPresentationActiveFile(state, file) {
            state.presentationActiveFile = file;
        },
        setOpenedContentFromLibrary(state, id) {
            let found = null;

            for (const section of state.sections) {
                found = section.sectionAssets.find(asset => asset.id == id);

                if (found) break;
            }

            state.openedContent = found;
        },
        setOpenedContentFromPresentation(state) {
            state.openedContent = state.presentationActiveFile;
        },
        nextFile(state, nextFile) {
            state.presentationActiveFile = nextFile;
        },
        prevFile(state, prevFile) {
            state.presentationActiveFile = prevFile
        },
        nextSection(state) {
            state.presentationActiveSection =
                state.sections.find(section => section.order == state.presentationActiveSection.order + 1);
        },
        prevSection(state) {
            state.presentationActiveSection =
                state.sections.find(section => section.order == state.presentationActiveSection.order - 1);
        },
        setVideoPlayer(state, videoPlayer) {
            state.videoPlayer = videoPlayer;
        },
        setIsVideoPlaying(state, isVideoPlaying) {
            state.isVideoPlaying = isVideoPlaying;
        },
        setLoadContentLoader(state, loader) {
            state.loadContentLoader = loader;
        },
        resetGuideState(state) {
            state.program = null;
            state.sections = [];
            state.libraryGroupBy = 'type';
            state.libraryActiveType = 'all';
            state.libraryActiveSection = null;
            state.presentationActiveSection = null;
            state.presentationActiveFile = null;
            state.openedContent = null;
            state.videoPlayer = null;
            state.isVideoPlaying = false;
            state.loadContentLoader = false;
        }
    },
    getters: {
        libraryItems(state) {
            if (state.libraryGroupBy == 'sections') {
                return state.libraryActiveSection.sectionAssets.filter(asset => asset.type != "sound");
            } else {
                let allAssets = [];
                state.sections.forEach(section => {
                    allAssets = [...allAssets, ...section.sectionAssets.filter(asset => asset.type != "sound")]
                });

                if (state.libraryActiveType == 'all') {
                    return allAssets;
                } else {
                    return allAssets.filter(asset => asset.type == state.libraryActiveType);
                }
            }
        },
        presentationItems(state) {
            return state.presentationActiveSection.sectionAssets.filter(asset => asset.presentationInclude);
        },
        lastSectionOrder(state) {
            const lastIndex = state.sections.length - 1;
            return state.sections[lastIndex].order;
        },
        presentationNextFile(state, getters) {
            const currentIndex = getters.presentationItems.findIndex(asset => asset.id == state.presentationActiveFile.id);
            const nextItem = getters.presentationItems[currentIndex + 1];

            if (nextItem) {
                return nextItem
            } else {
                return false;
            }
        },
        presentationPrevFile(state, getters) {
            const currentIndex = getters.presentationItems.findIndex(asset => asset.id == state.presentationActiveFile.id);
            const prevItem = getters.presentationItems[currentIndex - 1];

            if (prevItem) {
                return prevItem
            } else {
                return false;
            }
        }
    },
    actions: {
        async getContent({ commit, state, getters }, roomName) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/room/connect`, {
                    roomName,
                    role: "guide"
                });

                commit("getContent", res.data.program);
                commit("setLibraryActiveSection", state.sections[0]);
                commit("setPresentationActiveSection", state.sections[0]);
                commit("setPresentationActiveFile", getters.presentationItems[0]);

                return {
                    accessToken: res.data.roomData.accessToken,
                    roomSid: res.data.roomData.roomSid,
                    roomCreatedAt: res.data.roomData.roomCreatedAt
                }
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        bindRoomContent: firestoreAction(async ({ state, rootState, bindFirestoreRef }) => {
            try {
                await bindFirestoreRef('roomContent', firestore.collection('rooms').doc(rootState.twilio.room.sid));
                await bindFirestoreRef('questions', firestore.collection(`rooms/${rootState.twilio.room.sid}/questions`).orderBy("asked", "desc"));
            } catch (e) {
                throw e;
            }
        }),
        unbindRoomContent: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('roomContent');
            unbindFirestoreRef('questions');
        }),
        async loadContent({ rootState, state, commit }, hasSound = false) {
            try {
                commit("setLoadContentLoader", true);

                await firestore
                    .collection("rooms")
                    .doc(rootState.twilio.room.sid)
                    .update({
                        openedContent: state.openedContent.path,
                        openedContentType: state.openedContent.type,
                        videoPlaying: false,
                        schoolImageLoaded: false,
                        guideImageLoaded: false,
                        schoolVideoLoaded: false,
                        guideVideoLoaded: false,
                        hasSound
                    })

                commit("setLoadContentLoader", false);
            } catch (e) {
                throw e;
            }
        },
        async unloadContent({ rootState }) {
            try {
                await firestore
                    .collection("rooms")
                    .doc(rootState.twilio.room.sid)
                    .update({
                        openedContent: null,
                        openedContentType: null,
                        videoPlaying: false,
                        schoolImageLoaded: false,
                        guideImageLoaded: false,
                        schoolVideoLoaded: false,
                        guideVideoLoaded: false,
                        hasSound: false
                    })
            } catch (e) {
                throw e;
            }
        },
        async loadImage({ rootState }) {
            try {
                await firestore
                    .collection("rooms")
                    .doc(rootState.twilio.room.sid)
                    .update({
                        guideImageLoaded: true,
                    })
            } catch (e) {
                throw e;
            }
        },
        async loadVideo({ rootState }) {
            try {
                await firestore
                    .collection("rooms")
                    .doc(rootState.twilio.room.sid)
                    .update({
                        guideVideoLoaded: true,
                    })
            } catch (e) {
                throw e;
            }
        },
        nextFile({ getters, commit }) {
            if (!getters.presentationNextFile) return;

            commit("nextFile", getters.presentationNextFile);
        },
        prevFile({ commit, getters }) {
            if (!getters.presentationPrevFile) return;

            commit("prevFile", getters.presentationPrevFile);
        },
        nextSection({ state, getters, commit }) {
            if (state.presentationActiveSection.order >= getters.lastSectionOrder) return;

            commit("nextSection");
            commit("setPresentationActiveFile", getters.presentationItems[0]);
        },
        prevSection({ state, commit, getters }) {
            if (state.presentationActiveSection.order <= 1) return;

            commit("prevSection");
            commit("setPresentationActiveFile", getters.presentationItems[0]);
        },
        async playVideo({ state, commit, rootState }) {
            try {
                await state.videoPlayer.play();

                await firestore
                    .collection("rooms")
                    .doc(rootState.twilio.room.sid)
                    .update({
                        videoPlaying: true,
                    })

                commit('setIsVideoPlaying', true)

            } catch (e) {
                throw e;
            }
        },
        async pauseVideo({ state, commit, rootState }) {
            try {
                await state.videoPlayer.pause();

                await firestore
                    .collection("rooms")
                    .doc(rootState.twilio.room.sid)
                    .update({
                        videoPlaying: false,
                    })

                commit('setIsVideoPlaying', false)
            } catch (e) {
                throw e;
            }
        },
        async answerQuestion({ rootState }, question) {
            try {
                await firestore
                    .collection(`rooms/${rootState.twilio.room.sid}/questions`)
                    .doc(question.id)
                    .update({
                        answered: question.answered,
                    });
            } catch (e) {
                throw e;
            }
        }
    },
}
