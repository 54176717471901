var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library-menu-wrapper primary px-3 pt-1 d-flex flex-column"},[_c('div',{staticClass:"text-h6 white--text text-right mb-1"},[_vm._v("Βιβλιοθήκη")]),_c('div',{staticClass:"d-flex align-center justify-end white--text body-2 mb-4"},[_c('div',{class:{
        'primary--text text--darken-4 font-weight-medium':
          _vm.libraryGroupBy == 'type',
      },on:{"click":function($event){return _vm.setLibraryGroupBy('type')}}},[_vm._v(" Τύπος ")]),_c('div',{staticClass:"mx-2"},[_vm._v("|")]),_c('div',{class:{
        'primary--text text--darken-4 font-weight-medium':
          _vm.libraryGroupBy == 'sections',
      },on:{"click":function($event){return _vm.setLibraryGroupBy('sections')}}},[_vm._v(" Ενότητα ")])]),_c('div',{staticClass:"library-menu-inner flex-grow-1"},[(_vm.libraryGroupBy == 'type')?_c('div',{staticClass:"d-flex flex-column align-end body-1 white--text"},_vm._l((_vm.categories),function(category,index){return _c('div',{key:index,staticClass:"body-1 mb-1",class:{
          'primary--text text--darken-4 font-weight-medium':
            category.value == _vm.libraryActiveType,
        },on:{"click":function($event){return _vm.setLibraryActiveType(category.value)}}},[_vm._v(" "+_vm._s(category.viewValue)+" ")])}),0):_c('div',{staticClass:"white--text"},_vm._l((_vm.sections),function(section,index){return _c('div',{key:index,staticClass:"d-flex flex-column align-end"},[_c('div',{staticClass:"body-1 mb-1",class:{
            'primary--text text--darken-4 font-weight-medium':
              section.order == _vm.libraryActiveSection.order,
          },on:{"click":function($event){return _vm.setLibraryActiveSection(section)}}},[_vm._v(" "+_vm._s(section.order)+". "+_vm._s(section.title)+" ")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }