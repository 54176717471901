<template>
  <div class="d-flex header-wrapper">
    <img class="logo mr-auto my-1 ml-1" src="@/assets/logo.svg" />

    <div class="d-flex justify-center align-center mr-3">
      <div class="caption mr-1 font-weight-medium">Πρόγραμμα:</div>
      <div class="caption">{{ program.programLocales[0].title }}</div>
    </div>

    <v-btn
      class="close-btn"
      small
      depressed
      color="error"
      @click="dialog = true"
    >
      <v-icon>mdi-phone-hangup</v-icon>
    </v-btn>

    <div class="primary px-2 d-flex align-center font-weight-medium">
      <div class="body-1 white--text">{{ duration | minuteSeconds }}</div>
    </div>

    <v-dialog v-model="dialog" max-width="500">
      <div class="white pa-3">
        <div class="body-1 text-center mb-4">
          Θέλετε να αποχωρήσετε από την παρουσίαση;
        </div>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" class="mr-3" @click="onClose"
            >Αποχώρηση</v-btn
          >

          <v-btn color="primary" depressed @click="dialog = false"
            >Παραμονή</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      duration: 0,
      interval: null,
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      roomContent: (state) => state.guide.roomContent,
      roomCreatedAt: (state) => state.twilio.roomCreatedAt,
      program: (state) => state.guide.program,
    }),
  },
  created() {
    const countFrom = moment.utc(this.roomCreatedAt).toDate();

    const now = moment(new Date());

    this.duration = moment(now).diff(countFrom, "seconds");

    this.interval = setInterval(() => {
      this.duration++;
    }, 1000);
  },
  methods: {
    ...mapActions(["disconnect"]),
    onClose() {
      this.disconnect();

      this.$router.push({ path: "/" });
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  border-bottom: 1px solid #e0e0e0;
}

.logo {
  height: 24px;
}

.close-btn {
  border-radius: 0 !important;
  height: 100% !important;
}

.program-info {
  border-left: 1px solid #e0e0e0;
}
</style>