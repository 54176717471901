import twilio from "twilio-video";
import router from "@/router";

export default {
    state: {
        facingMode: 'environment',
        localTracks: null,
        room: null,
        roomCreatedAt: null,
        triggersetListeners: false,
        localRef: null,
        remoteRef: null,
        remoteConnected: false,
        remoteVideoState: false,
        schoolNoCamera: true,
        schoolNoMic: true,
        tracksMuted: {
            video: false,
            audio: false
        },
        zoom: null,
        zoomValue: null,
        videoTrack: null,
        capabilities: null,
        settings: null,
    },
    mutations: {
        setLocalTracks(state, localTracks) {
            state.localTracks = localTracks;
        },
        setVideoTrack(state, videoTrack) {
            state.videoTrack = videoTrack;
        },
        setZoom(state, zoom) {
            state.zoom = zoom;
        },
        setZoomValue(state, zoomValue) {
            state.zoomValue = zoomValue;
        },
        setRoom(state, room) {
            state.room = room;
        },
        setRoomCreatedAt(state, roomCreatedAt) {
            state.roomCreatedAt = roomCreatedAt;
        },
        setTriggersetListeners(state) {
            state.triggersetListeners = true;
        },
        setRefs(state, refs) {
            state.localRef = refs.local;
            state.remoteRef = refs.remote;
        },
        setRemoteConnected(state, connected = true) {
            state.remoteConnected = connected;
        },
        setRemoteVideoState(state, remoteVideoState) {
            state.remoteVideoState = remoteVideoState;
        },
        setSchoolNoCamera(state, schoolNoCamera) {
            state.schoolNoCamera = schoolNoCamera;
        },
        setSchoolNoMic(state, schoolNoMic) {
            state.schoolNoMic = schoolNoMic;
        },
        setFacingMode(state, facingMode) {
            state.facingMode = facingMode;
        },
        changeTrackState(state, params) {
            state.tracksMuted[params.kind] = params.action == 'mute' ? true : false;
        },
        resetTwilioState(state) {
            state.facingMode = 'environment';
            state.localTracks = null;
            state.room = null;
            state.roomCreatedAt = null;
            state.triggersetListeners = false;
            state.localRef = null;
            state.remoteRef = null;
            state.remoteConnected = false;
            state.remoteVideoState = false;
            state.tracksMuted = {
                video: false,
                audio: false
            };
            state.zoom = null;
            state.zoomValue = null;
            state.videoTrack = null;
            state.capabilities = null;
            state.settings = null;
            state.schoolNoCamera = true;
            state.schoolNoMic = true;
        }
    },
    actions: {
        async getLocalTracks({ commit, state }) {
            try {
                const localTracks = await twilio.createLocalTracks({
                    audio: true,
                    video: {
                        facingMode: state.facingMode,
                        height: 1080,
                        width: 1920,
                        frameRate: 60,
                    }
                })

                const videoTrack = localTracks.find((track) => track.kind == "video");


                commit('setLocalTracks', localTracks);
                commit('setVideoTrack', videoTrack);

                const capabilities = videoTrack.mediaStreamTrack.getCapabilities();
                const settings = videoTrack.mediaStreamTrack.getSettings();

                if (capabilities.zoom) {
                    commit("setZoom", {
                        min: capabilities.zoom.min,
                        max: capabilities.zoom.max,
                        step: capabilities.zoom.step,
                    });
                }

                if (settings.zoom) {
                    commit("setZoomValue", settings.zoom);
                }
            } catch (e) {
                throw new Error("setLocalTracks");
            }
        },
        async connectToRoom({ commit, dispatch, state }, roomName) {
            try {
                const roomInfo = await dispatch('getContent', roomName);

                await dispatch('getLocalTracks');
                const room = await twilio.connect(roomInfo.accessToken, { tracks: state.localTracks });
                commit('setRoom', room);
                commit('setRoomCreatedAt', roomInfo.roomCreatedAt);

                await dispatch("bindRoomContent");
            } catch (e) {
                if (e?.response?.status == 400) {
                    if (e.response.data.data.program) {
                        commit("toggleSnackbar", {
                            open: true,
                            color: "error",
                            text: 'Δεν υπάρχει ενεργό πρόγραμμα ή το πρόγραμμα δεν έχει ξεκινήσει ακόμα. Σας θυμίζουμε ότι μπορείτε να συνδεθείτε το νωρίτερο 10 λεπτά πριν την έναρξη ενός προγράμματος'
                        })
                    }
                } else {
                    if (e.message == 'setLocalTracks') {
                        commit("toggleSnackbar", {
                            open: true,
                            color: "error",
                            text: 'Δεν είχαμε πρόσβαση στην κάμερα ή το μικρόφωνό σας. Παρακαλούμε ελέγξτε αν λειτουργούν σωστά η αν χρησιμοποιούνται ήδη από άλλη εφαρμογή βιντεοκλήσεων (Zoom, Teams, Skype κλπ.)'
                        })
                    } else {
                        commit("toggleSnackbar", {
                            open: true,
                            color: "error",
                            text: 'Δεν μπορέσαμε να σας συνδέσουμε στην παρουσίαση. Δοκιμάστε άλλον κωδικό η δοκιμάστε ξανά αργότερα.'
                        })
                    }
                }

                throw e;
            }
        },
        setListeners({ state, commit }) {
            //====== HANDLE CONNECTED PARTICIPANTS ======
            state.room.participants.forEach((participant) => {
                if (participant.identity == 'school') {
                    participant.on("trackSubscribed", (track) => {
                        state.remoteRef.appendChild(track.attach());

                        if (track.kind == 'video') {
                            commit('setRemoteVideoState', track.isEnabled)
                            commit("setSchoolNoCamera", false);
                        }

                        if (track.kind == 'audio') {
                            commit("setSchoolNoMic", false);
                        }
                    });

                    participant.on("trackEnabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    participant.on("trackDisabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    commit("setRemoteConnected");
                }
            });

            //====== HANDLE REMOTE PARTICIPANTS EVENTS ======
            //------ participant connected ------

            state.room.on("participantConnected", (participant) => {
                console.log(`Participant "${participant.identity}" connected`);

                if (participant.identity == 'school') {
                    participant.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            if (publication.kind == 'video') {
                                commit('setRemoteVideoState', publication.isTrackEnabled);
                                commit("setSchoolNoCamera", false);
                            }

                            if (publication.kind == 'audio') {
                                commit("setSchoolNoMic", false);
                            }

                            const track = publication.track;
                            state.remoteRef.appendChild(track.attach());
                        }
                    });

                    participant.on("trackSubscribed", (track) => {
                        if (track.kind == 'video') {
                            commit('setRemoteVideoState', track.isEnabled)
                            commit("setSchoolNoCamera", false);
                        }

                        if (track.kind == 'audio') {
                            commit("setSchoolNoMic", false);
                        }

                        state.remoteRef.appendChild(track.attach());
                    });

                    participant.on("trackDisabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    participant.on("trackEnabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    commit("setRemoteConnected");
                }
            });

            //------ participant disconnected ------
            state.room.on("participantDisconnected", (participant) => {
                console.log(`Participant disconnected: ${participant.identity}`);

                if (participant.identity == 'school') {
                    if (!state.schoolNoCamera) {
                        const video = state.remoteRef.getElementsByTagName("video")[0];
                        video.pause();
                        state.remoteRef.removeChild(video);
                    }

                    if (!state.schoolNoMic) {
                        const audio = state.remoteRef.getElementsByTagName("audio")[0];
                        audio.pause();
                        state.remoteRef.removeChild(audio);
                    }

                    commit("setRemoteConnected", false);
                }
            });

            state.room.on("disconnected", room => {
                state.localTracks.forEach((track) => {
                    track.stop();
                });

                router.push({ path: "/" });
            })
        },
        changeRefs({ commit, dispatch }, refs) {
            commit('setRefs', refs);
            dispatch('attachTracks');
        },
        attachTracks({ commit, state }) {
            state.room.localParticipant.videoTracks.forEach((publication) => {
                state.localRef.appendChild(publication.track.attach());
            });

            state.room.participants.forEach((participant) => {
                console.log(`Participant "${participant.identity}" already connected`);

                if (participant.identity == 'school') {
                    participant.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            const track = publication.track;
                            state.remoteRef.appendChild(track.attach());

                            if (publication.kind == 'video') {
                                commit('setRemoteVideoState', publication.isTrackEnabled);
                                commit('setSchoolNoCamera', false);
                            }

                            if (publication.kind == 'audio') {
                                commit('setSchoolNoMic', false);
                            }
                        }
                    });

                    commit("setRemoteConnected");
                }
            })
        },
        detachTracks({ state }) {
            state.room.localParticipant.videoTracks.forEach((track) => {
                track.track.detach()
            });

            state.room.participants.forEach((participant) => {
                if (participant.identity == 'school') {
                    participant.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            publication.track.detach();
                        }
                    });
                }
            });
        },
        switchCamera({ state, commit }) {
            state.localTracks.forEach((track) => {
                if (track.kind == "video") {
                    track.stop();
                }
            });
            const newFacingMode = state.facingMode == "user" ? "environment" : "user";
            state.videoTrack.restart({ facingMode: newFacingMode });
            commit("setFacingMode", newFacingMode);

            const capabilities = state.videoTrack.mediaStreamTrack.getCapabilities();
            const settings = state.videoTrack.mediaStreamTrack.getSettings();

            if (capabilities.zoom) {
                commit("setZoom", {
                    min: capabilities.zoom.min,
                    max: capabilities.zoom.max,
                    step: capabilities.zoom.step,
                });
            }

            if (settings.zoom) {
                commit("setZoomValue", capabilities.zoom.min);
            }
        },
        changeTrackState({ state, commit }, params) {
            state.room.localParticipant.tracks.forEach((publication) => {
                if (publication.kind == params.kind) {
                    if (params.action == 'mute') {
                        publication.track.disable();
                    } else {
                        publication.track.enable();
                    }
                }
            })

            commit("changeTrackState", params);
        },
        async onZoom({ commit, state }, action) {

            let newZoomValue;

            if (action == "zoomin") {
                if (state.zoomValue + 0.5 > state.zoom.max) {
                    return
                }
                newZoomValue = state.zoomValue + 0.5;
            } else {
                if (state.zoomValue - 0.5 < state.zoom.min) {
                    return
                }
                newZoomValue = state.zoomValue - 0.5;
            }

            await state.videoTrack.mediaStreamTrack.applyConstraints({
                advanced: [{ zoom: newZoomValue }],
            });

            const settings = state.videoTrack.mediaStreamTrack.getSettings();

            commit("setZoomValue", parseFloat(settings.zoom.toFixed(2)))
        },
        disconnect({ state, commit, dispatch }) {
            state.room.disconnect();

            state.localTracks.forEach((track) => {
                track.stop();
            });

            commit("resetGuideState");
            commit("setQuestionBadge", false);

            dispatch("unbindRoomContent");
        }
    },
}
