<template>
  <div class="component-wrapper d-flex flex-column">
    <!-- <v-btn @click="$router.go(-1)">back</v-btn> -->
    <div class="flex-grow-1 d-flex">
      <div class="questions-sidebar primary d-flex flex-column py-1">
        <v-btn
          class="align-self-end mb-5"
          small
          text
          @click="$router.go(-1)"
          color="white"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Πισω
        </v-btn>

        <div class="subtitle-1 font-weight-medium white--text text-center mb-3">
          Ερωτήσεις
        </div>

        <div class="body-2 white--text text-center mb-1">Έχετε απαντήσει</div>

        <div class="body-2 font-weight-medium text-center white--text">
          {{ answered }}/{{ questions.length }}
        </div>
      </div>

      <div class="questions d-flex flex-column">
        <div
          class="questions-scroll pt-2 pb-4"
          v-if="questions.length > 0"
          ref="questionsScroll"
        >
          <div
            class="d-flex flex-column px-4"
            v-for="(question, index) in questions"
            :key="index"
          >
            <div class="d-flex align-center">
              <div class="body-1 mr-2">
                {{ question.question }}
              </div>

              <v-progress-circular
                class="ml-auto mr-4"
                size="12"
                width="2"
                indeterminate
                color="primary"
                v-if="loader.open && loader.id == question.id"
              ></v-progress-circular>

              <v-checkbox
                :input-value="question.answered"
                readonly
                :ripple="false"
                hide-details
                class="mt-0 pa-0"
                :class="{
                  'ml-auto': !loader.open || loader.id != question.id,
                }"
                @click="onAnswerQuestion(question.id, question.answered)"
                :loading="true"
              ></v-checkbox>
            </div>

            <v-divider
              class="my-2"
              v-if="index != questions.length - 1"
            ></v-divider>
          </div>
        </div>

        <div class="text-h6 mx-auto mt-12 px-4" v-if="questions.length == 0">
          Δεν έχουν υποβληθεί ερωτήσεις
        </div>
      </div>
    </div>

    <Buttons />
  </div>
</template>

<script>
import Buttons from "@/views/program/Buttons";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Buttons,
  },
  data() {
    return {
      loader: {
        open: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapState({
      questions: (state) => state.guide.questions,
    }),
    answered() {
      return this.questions.filter((q) => q.answered).length;
    },
  },
  mounted() {
    if (this.questions.length) {
      this.$vuetify.goTo(this.$refs.questionsScroll.clientHeight, {
        container: ".questions-scroll",
      });
    }
  },
  methods: {
    ...mapActions(["answerQuestion"]),
    async onAnswerQuestion(id, answered) {
      if (this.loader.open) return;

      this.loader = {
        open: true,
        id,
      };

      try {
        await this.answerQuestion({
          id,
          answered: !answered,
        });

        this.loader = {
          open: false,
          id: null,
        };
      } catch (e) {
        console.log(e);

        this.loader = {
          open: false,
          id: null,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-sidebar {
  width: 150px;
  flex-shrink: 0;
}

.questions {
  flex: 1 0 auto;
}

.questions-scroll {
  flex: 1 0 auto;
  overflow-y: auto;
  height: 0px;
}
</style>