export default {
    state: {
        snackbar: {
            open: false,
            text: null,
            color: null
        },
        menu: false,
        questionBadge: false,
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },
        toggleMenu(state, menu) {
            state.menu = menu
        },
        setQuestionBadge(state, questionBadge) {
            state.questionBadge = questionBadge
        },
    },
    actions: {

    },
}