<template>
  <div class="component-wrapper d-flex flex-column">
    <div class="d-flex flex-column pa-1 flex-grow-1">
      <div class="d-flex align-center">
        <div class="subtitle-1 font-weight-medium mr-3">
          Ενότητα: {{ presentationActiveSection.title }} ({{
            presentationActiveSection.order
          }}/{{ sections.length }})
        </div>

        <div class="body-2">
          ({{ presentationActiveFile.order }}/{{ presentationItems.length }}
          αρχεία)
        </div>
      </div>

      <router-view></router-view>
    </div>

    <Buttons />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Buttons from "@/views/program/Buttons";

export default {
  components: {
    Buttons,
  },
  computed: {
    ...mapState({
      sections: (state) => state.guide.sections,
      presentationActiveSection: (state) =>
        state.guide.presentationActiveSection,
      presentationActiveFile: (state) => state.guide.presentationActiveFile,
    }),
    ...mapGetters(["presentationItems"]),
  },
};
</script>

<style lang="scss" scoped>
</style>