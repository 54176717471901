<template>
  <div class="component-wrapper d-flex flex-column">
    <Header />

    <div class="d-flex flex-grow-1 video-wrapper">
      <div class="half remote" ref="remote">
        <div class="track-msg body-2 text-center" v-if="!remoteConnected">
          Το σχολείο δεν έχει συνδεθεί
        </div>

        <div
          class="track-msg body-1 text-center white--text"
          v-if="!remoteVideoState && remoteConnected"
        >
          Το σχολείο έχει κλείσει προσωρινά την κάμερα του
        </div>

        <div
          class="track-msg d-flex align-center"
          v-if="remoteConnected && schoolNoCamera"
        >
          <v-icon color="warning" class="mr-3">mdi-video-off</v-icon>

          <div class="body-2 warning--text">
            Το σχολείο έχει συνδεθεί χωρίς κάμερα.
          </div>
        </div>
      </div>

      <v-divider vertical dark></v-divider>

      <div class="half local" ref="local">
        <div
          class="track-msg white--text body-1 text-center"
          v-if="tracksMuted.video"
        >
          Η κάμερά σας είναι απενεργοποιημένη
        </div>

        <div
          class="zoom-btns d-flex pa-1"
          v-if="(zoom && zoomValue) || isDevelopment"
        >
          <v-btn
            icon
            color="white"
            class="primary ml-2 mr-2"
            @click="onZoom('zoomout')"
          >
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn>

          <v-btn icon color="white" class="primary" @click="onZoom('zoomin')">
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <Buttons />
  </div>
</template>

<script>
import Header from "@/views/program/Header";
import Buttons from "@/views/program/Buttons";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Header,
    Buttons,
  },
  data() {
    return {
      isDevelopment: process.env.NODE_ENV == "development",
    };
  },
  computed: {
    ...mapState({
      triggersetListeners: (state) => state.twilio.triggersetListeners,
      remoteConnected: (state) => state.twilio.remoteConnected,
      remoteVideoState: (state) => state.twilio.remoteVideoState,
      tracksMuted: (state) => state.twilio.tracksMuted,
      zoom: (state) => state.twilio.zoom,
      zoomValue: (state) => state.twilio.zoomValue,
      schoolNoCamera: (state) => state.twilio.schoolNoCamera,
    }),
  },
  mounted() {
    this.changeRefs({
      local: this.$refs.local,
      remote: this.$refs.remote,
    });

    if (!this.triggersetListeners) this.setTriggersetListeners();
  },
  methods: {
    ...mapActions(["changeRefs", "detachTracks", "onZoom"]),
    ...mapMutations(["setTriggersetListeners"]),
  },
  destroyed() {
    this.detachTracks();
  },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  height: 1px;
}

.half {
  flex-shrink: 0;
  flex-basis: 50%;
}

.zoom-btns {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
</style>