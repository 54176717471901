<template>
  <div class="component-wrapper d-flex flex-column">
    <div class="d-flex flex-column flex-grow-1">
      <v-btn
        class="align-self-start"
        small
        text
        v-if="
          $route.path.includes('/library/image') ||
          $route.path.includes('/library/video')
        "
        @click="$router.go(-1)"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Βιβλιοθηκη
      </v-btn>

      <router-view></router-view>
    </div>

    <Buttons />
  </div>
</template>

<script>
import Buttons from "@/views/program/Buttons";

export default {
  components: {
    Buttons,
  },
};
</script>

<style lang="scss" scoped>
</style>