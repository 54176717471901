<template>
  <div class="component-wrapper video d-flex py-2">
    <v-progress-circular
      class="ma-auto"
      size="50"
      indeterminate
      color="primary"
      v-if="loader"
    ></v-progress-circular>

    <vueVimeoPlayer
      ref="player"
      :video-url="openedContent.path"
      :controls="false"
      :options="{ muted: !openedContent.hasSound }"
      @ready="onLoadVideo"
      @playing="onPlaying"
      @pause="onPause"
      v-if="!loadContentLoader"
      v-show="!loader"
    />
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: { vueVimeoPlayer },
  data() {
    return {
      loadContentLoader: false,
    };
  },
  computed: {
    ...mapState({
      roomContent: (state) => state.guide.roomContent,
      remoteConnected: (state) => state.twilio.remoteConnected,
      openedContent: (state) => state.guide.openedContent,
    }),
    loader() {
      if (this.remoteConnected) {
        return (
          !this.roomContent.schoolVideoLoaded ||
          !this.roomContent.guideVideoLoaded
        );
      } else {
        return !this.roomContent.guideVideoLoaded;
      }
    },
  },
  async created() {
    this.loadContentLoader = true;
    await this.loadContent(this.openedContent.hasSound);
    this.loadContentLoader = false;
  },
  methods: {
    ...mapMutations([
      "setOpenedContentFromPresentation",
      "setVideoPlayer",
      "setIsVideoPlaying",
    ]),
    ...mapActions(["loadContent", "loadVideo", "unloadContent"]),
    onLoadVideo() {
      this.$refs.player.play();
    },
    onPlaying() {
      if (!this.roomContent.guideVideoLoaded) {
        this.$refs.player.pause();
      }
    },
    async onPause() {
      if (!this.roomContent.guideVideoLoaded) {
        this.setVideoPlayer(this.$refs.player);
        await this.loadVideo();
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    this.loadContentLoader = true;
    this.setOpenedContentFromPresentation();
    this.setIsVideoPlaying(false);
    await this.loadContent(this.openedContent.hasSound);
    this.loadContentLoader = false;

    next();
  },
  async beforeRouteLeave(to, from, next) {
    if (!to.path.includes("/image/")) this.unloadContent();

    this.setIsVideoPlaying(false);
    next();
  },
};
</script>

<style lang="scss" scoped>
</style>